<template>
  <form @submit.prevent="onSave" class="form-horizontal">
    <div class="form-group">
      <label class="font-weight-bold" for="email">Email</label>
      <input v-model="user.email" name="email" id="email" class="form-control" aria-describedby="email" />
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="firstName">First Name</label>
      <input v-model="user.firstName" name="firstName" id="firstName" class="form-control" aria-describedby="firstName" />
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="lastName">Last Name</label>
      <input v-model="user.lastName" name="lastName" id="lastName" class="form-control" aria-describedby="lastName" />
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="newPassword">New Password <span v-if="user.id" class="text-muted font-weight-light">Leave blank to not change password.</span></label>
      <input v-model="password.newPassword" type="password" name="newPassword" id="newPassword" class="form-control" aria-describedby="newPassword" />
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="newPasswordConfirm">Confirm Password <span v-if="user.id" class="text-muted font-weight-light">Leave blank to not change password.</span></label>
      <input v-model="password.newPasswordConfirm" type="password" name="newPasswordConfirm" id="newPasswordConfirm" class="form-control" aria-describedby="newPasswordConfirm" />
    </div>
    <div class="form-group" v-if="user.id">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="isLocked" v-model="user.isLocked" v-on:change="onIsLockedChange()">
        <label class="custom-control-label" for="isLocked">Is Locked Out</label>
        <label class="text-muted [ ml-2 ]" v-if="getLockedOutTime()">(Until {{getLockedOutTime()}})</label>
      </div>
    </div>
    <div class="form-group" v-if="user.id">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="isActive" v-model="user.isActive">
        <label class="custom-control-label" for="isActive">Is Active</label>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="isEmailConfirmed" v-on:change="onIsEmailConfirmedChange()" v-model="user.isEmailConfirmed">
        <label class="custom-control-label" for="isEmailConfirmed">Email Confirmed</label>
        <br>
        <label class="text-muted" v-if="showEmailConfirmWarning">Note: if manually confirmed, user potentially will not receive emails from the Portal. Please ensure the email is correct.</label>
      </div>
    </div>
    <div class="form-group pull-right">
        <LoadingPanel v-if="isLoading"></LoadingPanel>
        <button v-else type="submit" class="btn btn-primary">Save</button>
    </div>
  </form>
</template>

<script>
import LoadingPanel from '@/components/LoadingPanel'
import { dateToString, localTimeToUtc, addHours } from '@/utils'

export default {
  name: 'UserInput',

  components: {
    'LoadingPanel': LoadingPanel
  },

  props: [
    'isLoading',
    'user',
    'password'
  ],

  data () {
    return {
      showEmailConfirmWarning: false
    }
  },

  methods: {
    onSave: function() {
      this.$emit('onSave')
    },

    getLockedOutTime: function() {
      return this.user.isLocked ? dateToString(this.user.lockoutEnd) : null
    },

    onIsEmailConfirmedChange: function() {
      this.showEmailConfirmWarning = this.user.isEmailConfirmed
    },

    onIsLockedChange: function() {
      this.user.lockoutEnd = this.user.isLocked ? addHours(localTimeToUtc(), 1) : null
    }
  }
}
</script>