<template>
  <div>
    <LoadingBanner
      v-bind:title="'Edit User'"
      v-bind:isLoading="isFetchingUser"></LoadingBanner>
    <div class="alert alert-danger" role="alert" v-if="updateErrors.length > 0">
      There were errors on the page:
      <ul class="[ m-0 ]">
        <li v-for="(error) in updateErrors" v-bind:key="error.id">
          {{ error.value }}
        </li>
      </ul>
    </div>
    <div class="row [ mt-3 ]" v-if="!isFetchingUser || isUpdating">
      <div class="col-md-12 text-right">
        <LoadingPanel v-if="isDeleting"></LoadingPanel>
        <button v-on:click="onDelete" v-else type="button" class="btn btn-secondary">Delete</button>
      </div>
    </div>
    <div class="row" v-if="!isFetchingUser">
      <div class="col-md-12">
        <UserInput
          v-bind:isLoading="isUpdating"
          v-bind:user="user"
          v-bind:password="password"
          v-on:onSave="onUpdate"></UserInput>
      </div>
    </div>
    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingBanner from '@/components/LoadingBanner'
import MessageDisplay from '@/components/MessageDisplay'
import UserInput from '@/components/UserInput'
import { default as usersAPI } from '@/api/UsersAPI'
import { setSuccessMessage, setErrorMessage } from '@/utils'


export default {
  name: 'UserEditContainer',

  components: {
    'LoadingBanner': LoadingBanner,
    'MessageDisplay': MessageDisplay,
    'UserInput': UserInput
  },

  mounted() {
    this.onLoad()
  },

  computed: {
    isUpdating() {
      return this.isUpdatingUser || this.isUpdatingPassword
    }
  },

  data () {
    return {
      isFetchingUser: false,
      isUpdatingUser: false,
      isUpdatingPassword: false,
      isDeleting: false,
      errorMessage: null,
      successMessage: null,
      user: {},
      password: {},
      updateErrors: []
    }
  },

  methods: {
    ...mapGetters([
      'currentUser',
      'isAdmin'
    ]),

    onLoad: function () {
      this.isFetchingUser = true
      this.user = {}
      this.password = {}
      usersAPI
        .getByUserId(this.getUserIdParam())
        .then((user) => {
          this.user = user
        })
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isFetchingUser = false)
    },

    async onUpdate() {
      this.updateErrors = []
      await this.performUserUpdate()
      await this.performPasswordUpdate()
      if (this.updateErrors.length == 0) {
        setSuccessMessage(this, 'successMessage')
      }
    },

    async performUserUpdate() {
      this.isUpdatingUser = true
      await usersAPI
        .update(this.user)
        .catch((response) => {
          response.body.errors.forEach(error => {
            this.updateErrors.push(error)
          });
        })
        .finally(() => {
          this.isUpdatingUser = false
        })
    },

    async performPasswordUpdate() {
      if (this.password.newPassword) {
        this.isUpdatingPassword = true
        await usersAPI.updateUserPassword(this.user, this.password)
          .catch((response) => {
            response.body.errors.forEach(error => {
              this.updateErrors.push(error)
            })
          })
          .finally(() => {
            this.isUpdatingPassword = false
          })
      }
    },

    onDelete() {
      if(!confirm("Are you sure you want to completely delete this account?")) { return }
      this.isDeleting = true
      usersAPI
        .delete(this.user.id)
        .then(() => this.$router.push({ name: 'users', params: { isSuccess: 1 } }))
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isDeleting = false)
      
    },

    getUserIdParam() {
      return this.$route.params.userId
    }
  }
}
</script>