<template>
  <div>
    <LoadingBanner
      v-bind:title="'Confirming Email'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <LoadingPanel class="mt-3" v-if="isLoading"></LoadingPanel>

    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import LoadingPanel from "@/components/LoadingPanel"
import { default as usersAPI } from '@/api/UsersAPI'
import { setSuccessMessage, setErrorMessage } from '@/utils'

export default {
  name: 'UserEmailConfirmContainer',

  components: {
    'LoadingBanner': LoadingBanner,
    'LoadingPanel': LoadingPanel,
    'MessageDisplay': MessageDisplay,
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    load: function() {
      this.isLoading = true
      usersAPI
        .validateEmailToken(this.getTokenParam())
        .then(() => {
          this.$router.push({ name: 'login', params: { isRedirect: '3' }})
        })
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isLoading = false)
    },

    getTokenParam: function() {
      return this.$route.params.token
    }
  }
}
</script>

<style>

</style>