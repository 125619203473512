<template>
  <span>
    <i v-if="sortKey != sortKeyValue" class="fa fa-sort" aria-hidden="true"></i>
    <i v-else-if="isReverse" class="fa fa-sort-desc" aria-hidden="true"></i>
    <i v-else class="fa fa-sort-asc" aria-hidden="true"></i>
  </span>
</template>

<script>
export default {
  name: 'TableSortIcon',

  props: [
    'sortKey',
    'isReverse',
    'sortKeyValue'
  ]
}
</script>

<style>

</style>