<template>
  <div>
    <LoadingBanner
      v-bind:title="'Add User'"
      v-bind:isLoading="isLoading"></LoadingBanner>
    <div class="alert alert-danger" role="alert" v-if="insertErrors.length > 0">
      There were errors on the page:
      <ul class="[ m-0 ]">
        <li v-for="(error) in insertErrors" v-bind:key="error.id">
          {{ error.value }}
        </li>
      </ul>
    </div>
    <div class="row [ mt-5 ]">
      <div class="col-md-12">
        <UserInput
          v-bind:isLoading="isLoading"
          v-bind:user="user"
          v-bind:password="password"
          v-on:onSave="onInsert"></UserInput>
      </div>
    </div>
    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingBanner from '@/components/LoadingBanner'
import MessageDisplay from '@/components/MessageDisplay'
import UserInput from '@/components/UserInput'
import { default as usersAPI } from '@/api/UsersAPI'

export default {
  name: 'UserAddContainer',

  components: {
    'LoadingBanner': LoadingBanner,
    'MessageDisplay': MessageDisplay,
    'UserInput': UserInput
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      user: { isActive: true },
      password: {},
      insertErrors: []
    }
  },

  methods: {
    ...mapGetters([
      'currentUser',
      'isAdmin'
    ]),

    onInsert() {
      this.isLoading = true
      this.insertErrors = []
      usersAPI
        .insert(this.user, this.password)
        .then(() => this.$router.push({ name: 'users', params: { isSuccess: 1 } }))
        .catch((response) => {
          response.body.errors.forEach(error => {
            this.insertErrors.push(error)
          })
        })
        .finally(() => this.isLoading = false)
    }
  }
}
</script>