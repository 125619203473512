<template>
  <div>
    <LoadingBanner
      v-bind:title="'Account Settings'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="row mt-5">
      <div class="col-md-12">
        <div class="tabbable">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#profile" data-toggle="tab">Profile</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#password" data-toggle="tab">Password</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#data" data-toggle="tab" v-if="!isAdminUser()">Data</a>
            </li>
          </ul>
          <div class="tab-content mt-3">
            <!-- Profile -->
            <div class="tab-pane active" id="profile">
              <div class="alert alert-danger" role="alert" v-if="updateUserErrors.length > 0">
                There were errors on the page:
                <ul class="[ m-0 ]">
                  <li v-for="(error) in updateUserErrors" v-bind:key="error.id">
                    {{ error.value }}
                  </li>
                </ul>
              </div>
              <form @submit.prevent="onUpdateUser" class="form-horizontal">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input v-model="user.email" type="text" name="email" id="email" class="form-control" placeholder="Email" aria-describedby="email">
                </div>
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input v-model="user.firstName" type="text" name="firstName" id="firstName" class="form-control" placeholder="First Name" aria-describedby="firstName">
                </div>
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input v-model="user.lastName" type="text" name="lastName" id="lastName" class="form-control" placeholder="Last Name" aria-describedby="lastName">
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary" role="button">Save</button>
                </div>
              </form>    
            </div>

            <!-- Password -->
            <div class="tab-pane" id="password">
              <div class="alert alert-danger" role="alert" v-if="updatePasswordErrors.length > 0">
                There were errors on the page:
                <ul class="[ m-0 ]">
                  <li v-for="(error) in updatePasswordErrors" v-bind:key="error.id">
                    {{ error.value }}
                  </li>
                </ul>
              </div>
              <form @submit.prevent="onUpdatePassword" class="form-horizontal">
                <div class="form-group">
                  <label for="currentPassword">Current Password</label>
                  <input v-model="password.currentPassword" type="password" name="currentPassword" id="currentPassword" class="form-control" aria-describedby="currentPassword">
                </div>
                <div class="form-group">
                  <label for="newPassword">New Password</label>
                  <input v-model="password.newPassword" type="password" name="newPassword" id="newPassword" class="form-control" aria-describedby="newPassword">
                </div>
                <div class="form-group">
                  <label for="passwordRepeat">Confirm Password</label>
                  <input v-model="password.passwordConfirm" type="password" name="passwordRepeat" id="passwordRepeat" class="form-control" aria-describedby="passwordRepeat">
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary" role="button">Save</button>
                </div>
              </form>    
            </div>

            <!-- Delete Data -->
            <div class="tab-pane" id="data" v-if="!isAdminUser()">
              <div class="list-group">
                <div class="list-group-item list-group-item-action">
                  <h5>Remove Account Data</h5>
                  <div>
                    <em>Please select this option if you would like to clean up your account but remain active on the Carl Calvanico CPA Secure Portal for future use. This will remove all of your current data without affecting your account status.</em>
                  </div>
                  <ul class="delete-data-list [ mt-2 ]">
                    <li>
                      <span class="fa-stack fa-3x fa-sm">
                        <i class="fa fa-circle fa-stack-2x" style="color:green;"></i>
                        <strong class="fa-stack-1x text-white">1</strong>
                      </span>
                      <span>
                         Click here: <a v-on:click="removeData" href="#">Remove all Account Data</a>
                      </span>
                    </li>
                    <li>
                      <span class="fa-stack fa-3x fa-sm">
                        <i class="fa fa-circle fa-stack-2x" style="color:green;"></i>
                        <strong class="fa-stack-1x text-white">2</strong>
                      </span>
                      <span>
                         Select ‘OK’ in the verification window.
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="list-group-item list-group-item-action">
                  <h5 class="">Delete Account</h5>
                   <div>
                    <em>Please select this option if you would like to permanently delete your account on the Carl Calvanico CPA Secure Portal.</em>
                  </div>
                  <ul class="delete-data-list [ mt-3 ]">
                    <li>
                      <span class="fa-stack fa-3x fa-sm">
                        <i class="fa fa-circle fa-stack-2x" style="color:green;"></i>
                        <strong class="fa-stack-1x text-white">1</strong>
                      </span>
                      <span>
                         Click here: <a v-on:click="removeAccount" href="#">Delete Account</a>
                      </span>
                    </li>
                    <li>
                      <span class="fa-stack fa-3x fa-sm">
                        <i class="fa fa-circle fa-stack-2x" style="color:green;"></i>
                        <strong class="fa-stack-1x text-white">2</strong>
                      </span>
                      <span>
                         Select ‘OK’ in the verification window.
                      </span>
                    </li>
                  </ul>
                  <div class="alert alert-primary" role="alert">
                    Note: This deletion is permanent, and all your account data will be removed.
                  </div>    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import { default as usersAPI } from '@/api/UsersAPI'
import { cloneObject, setSuccessMessage, setErrorMessage } from '@/utils'

export default {
  name: "UserContainer",

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner
  },


  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      user: {},
      password: {},
      updateUserErrors: [],
      updatePasswordErrors: [],
      updateAdminSettingsErrors: []
    }
  },

  mounted () {
    this.user = cloneObject(this.currentUser())
  },

  methods: {
    ...mapGetters([
      'currentUser',
      'isAdminUser'
    ]),

    ...mapActions([
      'updateUser',
      'logout'
    ]),

    onUpdateUser: function() {
      this.isLoading = true
      usersAPI
        .update(this.user)
        .then((user) => {
          this.updateUser(user)
          this.user = user      // Mounted only gets called when component first created
          setSuccessMessage(this, 'successMessage')
          this.updateUserErrors = []
        })
        .catch((errors) => {
          this.updateUserErrors = errors.body.errors
        })
        .finally(() => this.isLoading = false)
    },

    onUpdatePassword: function() {
      this.isLoading = true
      usersAPI
        .updatePassword(this.password)
        .then(() => {
          setSuccessMessage(this, 'successMessage')
          this.updatePasswordErrors = []
        })
        .catch((errors) => {
          this.updatePasswordErrors = errors.body.errors
        })
        .finally(() => this.isLoading = false)
    },

    removeData: function() {
      if (confirm("Are you sure you want to completly remove your data?")) {
        this.isLoading = true
        usersAPI
          .clearData()
          .then(() => {
            setSuccessMessage(this, 'successMessage')
          })
          .catch((errors) => setErrorMessage(this, 'errorMessage'))
          .finally(() => this.isLoading = false)
      }
    },

    removeAccount: function() {
      if(confirm("Are you sure you want to completely delete your account?")) {
        this.isLoading = true
        usersAPI
          .removeAccount()
          .then(() => {
            this.logout()
            this.$router.push({ name: "login" })
          })
          .catch((errors) => setErrorMessage(this, 'errorMessage'))
          .finally(() => this.isLoading = false)
      }
    }
  }
};
</script>

<style scoped>
.fa-sm {
  font-size:1em;
}

.delete-data-list {
  list-style: none;
}

.list-group-item {
  border:none;
}
</style>