<template>
  <div>
    <LoadingBanner
      v-bind:title="'System Users'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="row mt-3">
      <div class="col-md-12">
        <form class="form-inline sm-max-width" style="float:left;">
          <div class="form-group sm-max-width">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search"></i>
                </span>
              </div>
              <input v-on:input="onSearch" type="text" name="search" id="search" class="form-control" placeholder="Search..."  aria-describedby="search">
            </div>
          </div>
        </form>
        <div class="pull-right sm-max-width">
          <router-link to="/invite" class="btn btn-secondary sm-max-width">Invite User</router-link>
          <router-link to="/add-user" class="btn btn-primary sm-max-width [ ml-2 ]" >Add User</router-link>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <table class="table table-responsive-sm">
          <thead>
            <tr>
              <th v-on:click="sortBy('firstName')">
                First Name 
                <TableSortIcon class="pull-right text-muted" 
                  v-bind:sortKey="'firstName'"
                  v-bind:isReverse="sortReverse"
                  v-bind:sortKeyValue="sortKey"></TableSortIcon>
              </th>
              <th v-on:click="sortBy('lastName')">
                Last Name
                <TableSortIcon class="pull-right text-muted" 
                  v-bind:sortKey="'lastName'"
                  v-bind:isReverse="sortReverse"
                  v-bind:sortKeyValue="sortKey"></TableSortIcon>
              </th>
              <th v-on:click="sortBy('email')">
                Email
                <TableSortIcon class="pull-right text-muted" 
                  v-bind:sortKey="'email'"
                  v-bind:isReverse="sortReverse"
                  v-bind:sortKeyValue="sortKey"></TableSortIcon>
              </th>
              <th v-on:click="sortBy('assetsUploadedCount')">
                Uploaded Docs
                <TableSortIcon class="pull-right text-muted" 
                  v-bind:sortKey="'assetsUploadedCount'"
                  v-bind:isReverse="sortReverse"
                  v-bind:sortKeyValue="sortKey"></TableSortIcon>
              </th>
              <th v-on:click="sortBy('assetsSharedCount')">
                Sent Docs
                <TableSortIcon class="pull-right text-muted" 
                  v-bind:sortKey="'assetsSharedCount'"
                  v-bind:isReverse="sortReverse"
                  v-bind:sortKeyValue="sortKey"></TableSortIcon>
              </th>
              <th v-on:click="sortBy('utcLastLogin')" class="d-none d-lg-table-cell">
                Last Login
                <TableSortIcon class="pull-right text-muted" 
                  v-bind:sortKey="'utcLastLogin'"
                  v-bind:isReverse="sortReverse"
                  v-bind:sortKeyValue="sortKey"></TableSortIcon>
              </th>
              <th>Is Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(id) in searchUserIds" v-bind:key="id">
              <td scope="row">{{ users[id].firstName }}</td>
              <td>{{ users[id].lastName }}</td>
              <td>{{ users[id].email }}</td>
              <td><router-link :to="'/admin-user-assets/' + id">{{ users[id].assetsUploadedCount }}</router-link></td>
              <td><router-link :to="'/admin-share/' + id">{{ users[id].assetsSharedCount }}</router-link></td>
              <td class="d-none d-lg-table-cell">{{ toLocalTime(users[id].utcLastLogin) }}</td>
              <td>
                <span v-if="id !== currentUser().id"> 
                  <i v-if="users[id].isActive" style="color:green;" class="fa fa-check-circle" aria-hidden="true"></i>
                  <i v-else style="color:red;" class="fa fa-times" aria-hidden="true"></i>
                </span>
                <span v-else>
                  <i style="color:green;" class="fa fa-check-circle" aria-hidden="true"></i>
                </span>
              </td>
              <td>
                <span v-if="id !== currentUser().id">
                 <div class="dropdown">
                  <i class="fa fa-ellipsis-v" id="dropdownMenuButton" data-toggle="dropdown"></i>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <router-link class="dropdown-item" :to="'/edit-user/' + id">Edit</router-link>
                  </div>
                </div>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
      
  </div>
</template>

<script>
import debounce from 'debounce'
import { mapActions, mapGetters } from 'vuex'
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import TableSortIcon from '@/components/TableSortIcon'
import { default as usersAPI } from '@/api/UsersAPI'
import { 
  utcToLocalTimeString, 
  setSuccessMessage, 
  setErrorMessage,
  sortCompare
} from '@/utils'

export default {
  name: 'UsersContainer',

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'TableSortIcon': TableSortIcon
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      searchUserIds: [],
      userIds: [],
      users: { }, /* { id, user } */
      sortKey: 'firstName',
      sortReverse: true
    }
  },

  mounted () {
    if (this.getIsSuccessParam()) {
      setSuccessMessage(this, 'successMessage')
    }
    this.load()
  },

  methods: {
    ...mapGetters([
      'currentUser'
    ]),

    load: function() {
      this.clearData();
      this.isLoading = true
      usersAPI
        .get()
        .then((users) => {
          users.forEach(user => {
            this.searchUserIds.push(user.id)
            this.userIds.push(user.id)
            this.users[user.id] = user
          });
          this.sortBy(this.sortKey)
        })
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isLoading = false)
    },

    toLocalTime (dateTime) {
      return utcToLocalTimeString(dateTime)
    },

    clearData: function (){
      this.searchUserIds = []
      this.userIds = []
      this.users = {}
    },

    onSearch: debounce(function (e) {
      this.searchUserIds = this.userIds.filter(p => 
        this.users[p].firstName.toLowerCase().includes(e.target.value.toLowerCase())
        || this.users[p].lastName.toLowerCase().includes(e.target.value.toLowerCase())
        || (this.users[p].firstName.toLowerCase() + ' ' + this.users[p].lastName.toLowerCase()).includes(e.target.value.toLowerCase())
      )
      this.sortBy(this.sortKey)
    }, 300),

    sortBy: function(sortKey) {
      this.sortReverse = (this.sortKey == sortKey) ? !this.sortReverse : false
      this.sortKey = sortKey

      this.searchUserIds.sort((a, b) => {
        var x = this.users[a][sortKey]
        var y = this.users[b][sortKey]
        return sortCompare(x, y)
      });

      if (this.sortReverse) {
        this.searchUserIds.reverse()
      }
    },

    getIsSuccessParam: function () {
      return this.$route.params.isSuccess
    }
  }
}
</script>

<style scoped>

th:hover {
  cursor: pointer;
}

/* Smaller than tablet */
@media only screen and (max-width: 550px) {
  .sm-max-width {
    width: 100%;
  }
}

</style>